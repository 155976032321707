import "styles/global.css";
import { PRODUCTION_ENV } from "utils/constants";
import initLoyaltyLion from "scripts/initLoyaltyLion";

export const onClientEntry = () => {
	fetch("/api/geo").then(response => response.json()).then(data => {
		window.__COUNTRY = data.country.code;
	}).catch(error => {
		console.error("Error during onClientEntry:", error);
		window.__COUNTRY = PRODUCTION_ENV ? "US" : "CA";
	});
};

//Called when the user changes routes, including on the initial load of the app
export const onRouteUpdate = () => {
	if (!window.loyaltylion) {
		!initLoyaltyLion(document, window.loyaltylion || []);
		window.loyaltylion.init({ token: `${process.env.GATSBY_LOYALTY_LION_TOKEN}` });
	}

	if (PRODUCTION_ENV)
		window.ElevarInvalidateContext?.();
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => !location?.state?.preserveScroll;