exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-account-forgot-password-js": () => import("./../../../src/templates/account/forgot-password.js" /* webpackChunkName: "component---src-templates-account-forgot-password-js" */),
  "component---src-templates-account-login-js": () => import("./../../../src/templates/account/login.js" /* webpackChunkName: "component---src-templates-account-login-js" */),
  "component---src-templates-account-logout-js": () => import("./../../../src/templates/account/logout.js" /* webpackChunkName: "component---src-templates-account-logout-js" */),
  "component---src-templates-account-password-setup-js": () => import("./../../../src/templates/account/password-setup.js" /* webpackChunkName: "component---src-templates-account-password-setup-js" */),
  "component---src-templates-account-profile-index-js": () => import("./../../../src/templates/account/profile/index.js" /* webpackChunkName: "component---src-templates-account-profile-index-js" */),
  "component---src-templates-account-profile-order-history-js": () => import("./../../../src/templates/account/profile/order-history.js" /* webpackChunkName: "component---src-templates-account-profile-order-history-js" */),
  "component---src-templates-account-profile-page-js": () => import("./../../../src/templates/account/profile/page.js" /* webpackChunkName: "component---src-templates-account-profile-page-js" */),
  "component---src-templates-account-register-js": () => import("./../../../src/templates/account/register.js" /* webpackChunkName: "component---src-templates-account-register-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog/category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-mod-pro-page-js": () => import("./../../../src/templates/modProPage.js" /* webpackChunkName: "component---src-templates-mod-pro-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-preview-js": () => import("./../../../src/templates/preview.js" /* webpackChunkName: "component---src-templates-preview-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "slice---src-components-announcement-bar-index-js": () => import("./../../../src/components/announcement-bar/index.js" /* webpackChunkName: "slice---src-components-announcement-bar-index-js" */),
  "slice---src-components-cart-index-js": () => import("./../../../src/components/cart/index.js" /* webpackChunkName: "slice---src-components-cart-index-js" */),
  "slice---src-components-footer-index-js": () => import("./../../../src/components/footer/index.js" /* webpackChunkName: "slice---src-components-footer-index-js" */),
  "slice---src-components-header-index-js": () => import("./../../../src/components/header/index.js" /* webpackChunkName: "slice---src-components-header-index-js" */)
}

